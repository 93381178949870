$BLACK: #000000;
$COPYBLACK: #414042;
$PLACEHOLDER: #999999;
$COPYWHITE: #dcdcdc;
$WHITE: #FFFFFF;
$RED: #e50000;
$CYAN: #24ade3;

/* Project colors */
$NUTECGREEN: #84916a;
$AQUAMARINE: #00afbc;
$EMERALD: #009a69;
$GRANITE: #9c7e00;
$TOPAZ: #948d82;
/* Standard object colors */

/* Fonts */
$_STDFont: 'Roboto', Arial, sans-serif;
$_STDFontBold: 'Roboto', Arial, sans-serif;
$_STDFontCondensed: 'Roboto Condensed', sans-serif;
$_STDFontSize_h1: 30px;
$_STDFontSize_p: 20px;

/* Project Contect */
$PageContentWidth: 1600px;
/* Responsive sizes */
$MOBILEMAX: 800;

