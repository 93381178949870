@import 'normalize';

/* ==========================================================================
   Imports/Includes
   ========================================================================== */
@import 'variables';
/*@import 'fonts';*/
@import 'mixins';


html {
    min-height: 100% !important;
    height: 100%;
}
body {
	min-height: 100% !important;
    height: 100%;
}
h1, h2, h3, h4, h5 {
	font-weight:normal;
	margin:0px 0px 0px 0px;
	padding:0px 0px 0px 0px;
	text-align:center;
	text-transform:none;
	line-height:1;
}

a:focus, a:active {
   background-color: #FFFFFF;
   color:#000000 !important;
}

/* GENERAL */
h1, h2, p { color:$BLACK; text-align:left; }
p, li, td { font-family: $_STDFont; font-size:12px; }

button {
	border:0px solid $WHITE;
}


/* == OTHER WEBSITE STYLE SHEETS */
nav.topnav {
	position:fixed;
	top:0;
	left:0;
	z-index: 1000;
	width:100%;
	height:87px;
	background-color:#f7f7f7;
	box-sizing: border-box;
	@include breakWidth(800) {
		height:60px;
	}
	.main-container {
		position:relative;
		width:980px;
		height:100%;
		box-sizing: border-box;
		left:50%;
		margin-left:-490px;
		@include breakWidth(800) {
			width:300px;
			margin-left:-150px;	
		}
		.kolok-logo {
			padding-top:10px;
			width:170px;
			@include breakWidth(800) {
				width:120px;	
			}
		}
		.nutec-logo {
			padding-top:20px;
			width:124px;
			margin-right:60px;
			@include breakWidth(800) {
				width:80px;	
				padding-top:14px;
			}
		}
		button {
			position:absolute;
			top:17%;
			right:0;

			display:block;

			width:45px;
			height:66%;
			background-image:url(../images/svg/topnav-menu-drop-toggle.php?color=bcbec0);
			background-color:transparent;
			background-size:80% 80%;
			background-repeat: no-repeat;
			background-position:center center;
			&:hover {
				background-color:$NUTECGREEN;
				background-image:url(../images/svg/topnav-menu-drop-toggle.php?color=FFFFFF);
				transition:0.5s all;
			}
		}
		.dropdown-nav-container {
			position:absolute;
			top:100%;
			background-color:#626366;
			width:280px;
			right:0;
			display:none;
			ul {
				list-style:none;
				margin:20px 0 0 0;
				padding:0 0 0 0;
				li {
					border-width:0 0 1px 0;
					border-style:solid;
					border-color:$WHITE;
					text-align:left;
					margin:0 0 0 0;
					padding:0 0 0 15px;
					font-weight:bold;
					&.listhead {
						padding:0 0 0 15px;
					}
					ul {
						margin:0 0 0 10px;
						li {
							border:none;
							font-weight:normal;
						}
					}
					&:last-child {
						border:none;
						padding-bottom:10px;
					}
					a, .navlink-href {
						display:block;
						margin:0 0 0 0;
						padding:0 15px 0 15px;
						cursor:pointer;
						&:hover {
							background-color:$NUTECGREEN;
							cursor:pointer;
						}
					}
				}
				a, li {
					line-height:35px;
					color:$WHITE;
					font-size:16px;
					text-decoration: none;
				}
				.navlink-href {
					cursor:pointer;
					&:hover {
						background-color:$NUTECGREEN;
						cursor:pointer;
					}
				}
			}
		}
	}
}

@import '../../node_modules/toastr/toastr';

